<template>
  <div class="send">
    <Title />
    <v-img class="send-triangle-top" src="../assets/public/triangle_top.png" />

    <div class="send-container">
      <span class="send-title">{{$t("send_message02_1")}}</span>
      <br>
      <span class="send-title">{{$t("send_message02_2")}}</span>
      <br>
      <span class="send-title">{{$t("send_message02_3")}}</span>

      <div class="form-send-button" @click="$router.push('/akiya')">
        <span class="text_to_top">{{$t("page17_exit")}}</span>
      </div>
    </div>


    <v-img class="send-triangle-bottom" src="../assets/public/triangle_bottom.png" />
  </div>
</template>

<script>
import Title from "../components/title/Title.vue"
export default {
  name: 'Send',
  components: {
    Title
  }
}
</script>

<style scoped lang="scss">
.send {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: #fafafa;
  font-family: "ＭＳ Ｐゴシック", "MS PGothic";

  .send-triangle-top {
    position: absolute;
    top: 0;
    right: 0;
  }
  .send-triangle-bottom {
    width: 75%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .send-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0;
    height: 100%;
    width: 100%;

    .send-title {
      font-size: max(22px, 2.5vw);
      font-weight: bold;
      color: #132d7a;
    }
  }

  .form-send-button {
    margin-top: 50px;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 25%;
    background-color: #132d7a;
    border-radius: 15px;
    cursor: pointer;
    transition: .5s;

    &:hover {
      opacity: .8;
    }

    .text_to_top {
      color: #fafafa;
      font-size: max(20px, 1.5vw);
      font-weight: bold;
      letter-spacing: 0.6rem;
    }
  }
}
</style>
